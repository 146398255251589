import { GlobalAlert } from '@thoughtspot/global-alert';
import { useTranslation } from '@thoughtspot/i18n';
import {
    collectEvent,
    UserAction,
    userActionTypeEnum,
} from '@thoughtspot/metrics';
import { ToastAlertProps } from '@thoughtspot/radiant-react/widgets/alert';
import { Link } from '@thoughtspot/radiant-react/widgets/link';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import {
    Colors,
    Typography,
} from '@thoughtspot/radiant-react/widgets/typography';
import { isOrgsOnFTEnabled } from '@thoughtspot/session-service';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { UserMenuContainer } from '../../microfrontends/coms';
import styles from './user-menu.module.scss';

const InviteUsersButtonContainer = React.lazy(() =>
    import(
        '../invite-users-v2/invite-users-button/invite-users-button.container'
    ),
);

export interface UserMenuContainerWithInviteProps {
    onEmailAdd: any;
    triggerV1Logout?: () => void;
}

export const UserMenuContainerWithInvite: React.FC<UserMenuContainerWithInviteProps> = ({
    onEmailAdd,
    triggerV1Logout,
}) => {
    const { t } = useTranslation();
    const [toastAlert, updateToastAlert] = React.useState<ToastAlertProps>({
        isVisible: false,
        message: '',
    });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const onInviteLinkClick = () => {
        collectEvent(
            new UserAction(userActionTypeEnum.INVITE_TEAMMATES_USER_MENU),
            {},
        );
        setIsModalOpen(true);
    };
    return (
        <Suspense fallback={<LoadingIndicator />}>
            <UserMenuContainer
                inviteTeammates={
                    isOrgsOnFTEnabled() && (
                        <div className={styles.optionSection}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                                className={styles.profileLink}
                                noUderline
                                onClick={onInviteLinkClick}
                            >
                                <Typography
                                    variant="body-normal"
                                    color={Colors.base}
                                    className={styles.optionText}
                                >
                                    {t('homehub.task.finish.invite')}
                                </Typography>
                            </Link>
                        </div>
                    )
                }
                onMenuClose={_.noop}
                triggerV1Logout={triggerV1Logout}
            />
            {isModalOpen && isOrgsOnFTEnabled() && (
                <InviteUsersButtonContainer
                    isFullWidth
                    onEmailAdd={onEmailAdd}
                    updateToastAlert={updateToastAlert}
                    isInviteLinkCall
                    onInviteComplete={() => setIsModalOpen(false)}
                />
            )}
            <GlobalAlert.Toast {...toastAlert} />
        </Suspense>
    );
};
