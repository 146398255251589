/* eslint-disable max-classes-per-file */
import { useApolloClient } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { QueryData } from '@apollo/client/react/data';
import {
    QueryDataOptions,
    QueryHookOptions,
    QueryResult,
} from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql';
import _ from 'lodash';
import { useEffect, useReducer, useRef } from 'react';

export interface DocumentTypeDecoration<TResult, TVariables> {
    /**
     * This type is used to ensure that the variables you pass in to the query are assignable to Variables
     * and that the Result is assignable to whatever you pass your result to. The method is never actually
     * implemented, but the type is valid because we list it as optional
     */
    __apiType?: (variables: TVariables) => TResult;
}
export interface TypedDocumentNode<
    TResult = {
        [key: string]: any;
    },
    TVariables = {
        [key: string]: any;
    }
> extends DocumentNode, DocumentTypeDecoration<TResult, TVariables> {}

/**
 * QueryDataEntry class to store each of the unique useQuery call.
 */
class QueryDataEntry<TData, TVariables> {
    private listeners;

    private result: QueryResult<TData, TVariables>;

    constructor(private queryData: QueryData<TData, TVariables>) {
        this.listeners = new Set();
        this.updateResult();
    }

    public getQueryData() {
        return this.queryData;
    }

    public getResult() {
        return this.result;
    }

    public updateResult() {
        // Execute performs the read operation on the apollo cache,
        // this was the original cause of the bloating of memory and
        // main cause of the time delay. We are caching this result instead here.
        this.result = this.queryData.execute();
    }

    public getListeners() {
        return this.listeners;
    }

    public executeListeners(type: string, ...args: any) {
        this.listeners.forEach(listener => listener[type]?.call(null, ...args));
    }
}

/**
 * QueryCache class to maintain all the entries of QueryDataEntry
 * and maintain them uniquely with the key
 */
class QueryCache<TData, TVariables> {
    private cache: Map<string, QueryDataEntry<TData, TVariables>>;

    constructor() {
        this.cache = new Map();
    }

    public addEntry(key: string, queryData: QueryData<TData, TVariables>) {
        this.cache.set(key, new QueryDataEntry(queryData));
        return this.getEntry(key);
    }

    public getEntry(key: string) {
        return this.cache.get(key);
    }

    public removeEntry(key: string) {
        this.cache.delete(key);
    }
}

/**
 * Creates a new instance of the QueryData object for the given list of options.
 * QueryData is the apollo's internal class which takes care of making the API call
 * and adding the watcher's list.
 * @param options query options
 * @param context apollo context
 * @param onData onData callback
 * @param onError onError callback
 * @param onCompleted onCompleted callback
 * @returns QueryData
 */
function createQueryData<TData, TVariables>(
    options: QueryDataOptions<TData, TVariables>,
    context: any,
    onData: () => void,
    onError: () => void,
    onCompleted: () => void,
) {
    const updatedOptions = {
        ...options,
        onError,
        onCompleted,
    };
    const queryData = new QueryData<TData, TVariables>({
        options: updatedOptions as QueryDataOptions<TData, TVariables>,
        context,
        onNewData() {
            // When new data is received from the `QueryData` object, we want to
            // force a re-render to make sure the new data is displayed.
            // eslint-disable-next-line promise/catch-or-return
            Promise.resolve().then(() => onData());
        },
    });

    queryData.setOptions(updatedOptions);
    queryData.context = context;
    return queryData;
}

/**
 * We make use of an internal cache here to maintain unique version of queries
 * and create the QueryData objects sparingly to reduce the number of watchers.
 * @param options query options
 * @param key unique key object for caching
 * @param cache cache store
 * @returns QueryData
 */
function useCachedQueryData<TKey, TValue>(
    options: any,
    key: TKey,
    cache: QueryCache<any, any>,
): QueryResult<any, any> {
    // create a unique string key from the query options.
    const stringKey = JSON.stringify(key);
    const {
        updatedOptions,
        context,
        forceUpdate,
        onError,
        onCompleted,
    } = options;

    // Check if the query already exists in the cache, create new if it doesn't.
    let queryDataEntry = cache.getEntry(stringKey);
    if (!queryDataEntry) {
        queryDataEntry = cache.addEntry(
            stringKey,
            createQueryData(
                updatedOptions,
                context,
                // Subscribe to onNewData, onError and onComplete events to trigger all the listeners
                // from across the components
                () => {
                    queryDataEntry.updateResult();
                    queryDataEntry.executeListeners('onUpdate');
                },
                (...args) =>
                    queryDataEntry.executeListeners('onError', ...args),
                (...args) =>
                    queryDataEntry.executeListeners('onComplete', ...args),
            ),
        );
    }

    const queryData = queryDataEntry.getQueryData();
    const queryResult = queryDataEntry.getResult();

    // For every new component using the useQuery hook of already existing query,
    // store their listeners of update, error and complete.
    useEffect(() => {
        const currentEntry = cache.getEntry(stringKey);
        const listener = {
            onUpdate: forceUpdate,
            onError,
            onComplete: onCompleted,
        };
        currentEntry?.getListeners().add(listener);

        // cleanup the listener on the component unmount, or update
        return () => {
            currentEntry?.getListeners().delete(listener);
        };
    }, [forceUpdate, onError, onCompleted, stringKey]);

    // Clean up the query data entry from cache when no listeners are left for it.
    useEffect(() => {
        const currentKey = stringKey;
        return () => {
            // When none of the listeners are subscribed,
            // it is time to delete the query completely from the cache.
            const currentEntry = cache.getEntry(currentKey);
            if (currentEntry && !currentEntry.getListeners().size) {
                currentEntry.getQueryData().cleanup();
                cache.removeEntry(currentKey);
            }
        };
    }, [stringKey]);

    // Trigger queryData's afterExecute, which should check for new updates
    // and trigger the onData callback.
    useEffect(() => {
        queryDataEntry?.getQueryData().afterExecute();
    }, [
        queryResult.loading,
        queryResult.networkStatus,
        queryResult.error,
        queryResult.data,
        queryData.currentObservable,
    ]);

    return queryResult;
}

function cleanOptionsForMemoKey(options: any) {
    return {
        ...options,
        variables: {
            ...options.variables,
            // genNo and genNoWorkingSet always update any of the changes on answer,
            // this causes us to create un-necessary instances of QueryData.
            ...(options.variables?.session
                ? {
                      session: {
                          ...options.variables.session,
                      },
                  }
                : null),
        },
        // `onError` and `onCompleted` callback functions will not always have a
        // stable identity, so we'll exclude them from the memoization key to
        // prevent `afterExecute` from being triggered un-necessarily.
        onError: null,
        onCompleted: null,
    };
}

function createCachedQuery() {
    /**
     * Maintain a place to store the results of the QueryData, and use them for future responses.
     */
    const storedResults: any = new QueryCache();

    return <TData = any, TVariables = OperationVariables>(
        query: DocumentNode | TypedDocumentNode<TData, TVariables>,
        options?: QueryHookOptions<TData, TVariables>,
    ) => {
        const client = useApolloClient();
        const context = { client };
        const isMounted = useRef(false);
        // hook to force re-render, on the event of new data.
        const [_, forceUpdate] = useReducer(
            x => (isMounted.current ? x + 1 : x),
            0,
        );
        const updatedOptions = options ? { ...options, query } : { query };

        const key = cleanOptionsForMemoKey(updatedOptions) as QueryHookOptions<
            TData,
            TVariables
        >;
        const { onError, onCompleted } = options;

        useEffect(() => {
            isMounted.current = true;
            return () => {
                isMounted.current = false;
            };
        }, []);

        const result = useCachedQueryData(
            {
                updatedOptions,
                context,
                forceUpdate,
                onError,
                onCompleted,
            },
            key,
            storedResults,
        );

        return result;
    };
}

export const useCachedQuery = createCachedQuery();
