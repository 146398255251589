import { useTranslation } from '@thoughtspot/i18n';
import { PropertyMap } from '@thoughtspot/metrics';
import _ from 'lodash';
import React from 'react';
import { useAppContext } from '/@contexts/global-app-context';
import { useGetSetupState } from '/@services/onboarding/onboarding-service';
import { useUpdateUserPreferences } from '/@services/user-admin/user-admin-service';
import {
    StepperProgressSteps,
    StepStatus,
} from '../../radiant-candidate/stepper-progress-bar/stepper-progress-bar';
import { SetupProgress } from './setup-progress';

export const AUTO_WORKSHEET_COMPLETE = 'autoWorksheetComplete';
export const CONNECTION_ID = 'connectionId';

export const SetupProgressContainer: React.FC = () => {
    const { metricsService, navService, sessionService } = useAppContext();
    const { error, data, loading } = useGetSetupState();
    const { t } = useTranslation();
    const { updateUserPreferences } = useUpdateUserPreferences();
    let propertyMap: PropertyMap;
    const steps: StepperProgressSteps[] = [
        {
            name: t('setup.progressBar.step.connect'),
            status: StepStatus.inProgress,
        },
        {
            name: t('setup.progressBar.step.model'),
            status: StepStatus.pending,
        },
        {
            name: t('setup.progressBar.step.visualize'),
            status: StepStatus.pending,
        },
    ];
    if (data) {
        if (data.answerHeaders.length > 0) {
            steps[0].status = StepStatus.done;
            steps[1].status = StepStatus.done;
            steps[2].status = StepStatus.done;
        } else if (
            data.answerHeaders.length === 0 &&
            !_.isNil(data.selectedWorksheetHeader) &&
            !_.isEmpty(data.selectedDataSourceId)
        ) {
            steps[0].status = StepStatus.done;
            steps[1].status = StepStatus.done;
            steps[2].status = StepStatus.inProgress;
        } else if (
            !_.isNil(data.selectedWorksheetHeader) ||
            !_.isEmpty(data.selectedDataSourceId)
        ) {
            steps[0].status = StepStatus.done;
            steps[1].status = StepStatus.inProgress;
            steps[2].status = StepStatus.pending;
        }
        propertyMap = {
            hasDataConnected: !_.isEmpty(data.selectedDataSourceId),
            hadDataModeled: !_.isNil(data.selectedWorksheetHeader),
            hasDataVisualized: data.answerHeaders.length > 0,
        };
    }

    return (
        <>
            {!loading && (
                <SetupProgress
                    steps={steps}
                    goToSetup={navService.goToSetup}
                    propertyMap={propertyMap}
                    collectEvent={metricsService.collectEvent}
                    updateUserPreferences={updateUserPreferences}
                    userGuid={sessionService.getUserGuid()}
                    getExposedUserPreferences={
                        sessionService.getExposedUserPreferences
                    }
                />
            )}
        </>
    );
};
